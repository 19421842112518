import type { GetServerSideProps, NextPage } from 'next';
import { z } from 'zod';
import { ssrThemeProps } from '@stitch-fix/sf-next/server';
import querystring from 'querystring';
import { getCachedEntryByUrl } from '../utils/requests/contentstack/cachedRequests';
import { formatError, serverLogger } from '../utils/loggers/server';
import { modularPageWrapperSchema } from '../components/ModularPageWrapper/schema';
import { ModularPageWrapper } from '../components/ModularPageWrapper';
import { isAllSettledResponseFailure } from '../utils/promises';
import { getDynamicAdsRequest } from '../features/dynamicAds/utils/getDynamicAdsRequest';
import { getModulesWithHeroOverride } from '../features/dynamicAds/utils/getModulesWithHeroOverride';
import { isDynamicAdsLandingPage } from '../utils/redirects';
import { queryAllocation } from './t/gateway/queryAllocation';

export type GatewayPageProps = z.infer<typeof modularPageWrapperSchema>;

export const getServerSideProps: GetServerSideProps<GatewayPageProps> = async ({
  locale,
  req,
  query,
}) => {
  const url = req.url || '';

  if (!isDynamicAdsLandingPage(url)) {
    const gatewayAllocation = await queryAllocation({
      req,
      query,
      offlineLookupOnly: false,
    });

    if (gatewayAllocation.includes('treatment')) {
      const stringifiedQuery = querystring.stringify(query);

      return {
        redirect: {
          destination: `/t/gateway${
            stringifiedQuery ? `?${stringifiedQuery}` : ''
          }`,
          permanent: false,
        },
      };
    }
  }

  try {
    const dynamicAdsRequest = getDynamicAdsRequest({ req });
    const entryRequest = getCachedEntryByUrl({
      contentType: 'modular_page',
      url: '/gateway-onboarding-vision',
      language: locale,
      schema: modularPageWrapperSchema,
      logger: serverLogger,
    });

    const responses = await Promise.allSettled([
      entryRequest,
      dynamicAdsRequest,
    ]);

    const [entryResponse, dynamicAdsResponse] = responses;
    const dynamicAdsData =
      dynamicAdsResponse.status === 'fulfilled'
        ? dynamicAdsResponse.value?.data
        : undefined;

    if (isAllSettledResponseFailure(entryResponse)) {
      throw entryResponse.reason;
    }

    return {
      props: {
        seoData: entryResponse.value.data.seoData,
        modules: getModulesWithHeroOverride({
          modules: entryResponse.value.data.modules,
          dynamicAds: dynamicAdsData,
        }),
        ...(await ssrThemeProps({
          req,
          overrideTheme: 'brand-2024-full',
        })),
      },
    };
  } catch (error: unknown) {
    serverLogger({
      level: 'error',
      team: 'first-touch',
      message: 'Required page data is undefined - Gateway',
      context: {
        error: formatError(error),
        page: '/',
        severity: 'critical',
      },
    });

    throw new Error('Required data not provided to render Gateway page');
  }
};

const GatewayPage: NextPage<GatewayPageProps> = ({ seoData, modules }) => {
  return <ModularPageWrapper seoData={seoData} modules={modules} />;
};

export default GatewayPage;
